@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'LynkcoType-Light';
  src: local('LynkcoType-Light'),
  url('./fonts/LynkcoType-Light.woff') format('woff'),
  url('./fonts/LynkcoType-Regular.woff2') format('woff2');
  font-weight: light;
  font-style: light;
}

@font-face {
  font-family: 'LynkcoType-Regular';
  src: local('LynkcoType-Regular'),
  url('./fonts/LynkcoType-Regular.woff') format('woff'),
  url('./fonts/LynkcoType-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LynkcoType-Medium';
  src: local('LynkcoType-Medium'),
  url('./fonts/LynkcoType-Medium.woff') format('woff'),
  url('./fonts/LynkcoType-Medium.woff2') format('woff2');
  font-weight: medium;
  font-style: medium;
}

@font-face {
  font-family: 'LynkcoType-Bold';
  src: local('LynkcoType-Bold'),
  url('./fonts/LynkcoType-Bold.woff') format('woff'),
  url('./fonts/LynkcoType-Bold.woff2') format('woff2');
}

.text-lynk-heading-xlarge {
  font-family: 'LynkcoType-Medium';
  /* line-height: 46px; */
}

.text-lynk-heading-large {
  font-family: 'LynkcoType-Medium';
  line-height: 28px;
}

.text-lynk-heading-small {
  font-family: 'LynkcoType-Medium';
  line-height: 20px;
}

.text-lynk-body-regular {
  font-family: 'LynkcoType-Regular';
  line-height: 16px;
}

.text-lynk-body-medium {
  font-family: 'LynkcoType-Medium';
  line-height: 18px;
}

.text-lynk-body-medium-regular {
  font-family: 'LynkcoType-Regular';
  line-height: 20px;
}

.text-lynk-turquoise-500 {
  color: #1EF1C6
}

.text-lynk-neutral-200 {
  color: #B3B3B3
}

.bg-lynk-neutral-400 {
  background-color: #464646;
  border-color: #464646;
}

.bg-lynk-neutral-500 {
  background-color: #393939;
}

.bg-lynk-neutral-700 {
  background-color: #262626;
  border-color: #262626;
}

.bg-lynk-turquoise-500 {
  background-color: #1EF1C6;
}

.bg-lynk-turquoise-700 {
  background-color: #099E80;
}

.bg-lynk-neutral-900 {
  background-color: #131313;
  border-color: #131313;
}

.body-description span.highlight {
  font-family: 'LynkcoType-Medium';
  color: #fff;
}

.body-description span.subheading {
  display: block;
  font-family: 'LynkcoType-Medium';
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #fff;
}

.animation-shimmer-horizontal {
  background: linear-gradient(90deg, #1EF1C6, #1EF1C6, #26c0fc, #1EF1C6, #1EF1C6);
  background-size: 400% 400%;
  background-color: transparent;
  border-color:  transparent !important;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

@keyframes swipe-up {
  0%, 100% { transform: rotate(-5deg); }
  50% { transform: rotate(5deg); }
}

.animate-swipe-up {
  animation: swipe-up 1s ease-in-out infinite;
}






.bg-lynk-grey-500 {
  background-color: #424242;
}

.bg-lynk-black {
  background-color: #262626;
}

.btn-primary {
  border-color: #1EF1C6;
}

.btn-primary:active {
  background-color: #099E80;
  border-color: #099E80;
}

.btn-secondary {
  
}

.btn-secondary:active {
  opacity: 0.4;
}




img {
  object-fit: cover; /* bug fix for Safari */
}

article img {
  object-fit: contain;
}


/* fixes tables to have full width and also overflow x working */
tbody {
  display: inline-table;
  width: 100%;
}



/* custom Lynk & Co style lists */
#listItem::after {
  content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6.00111L1.00278 11' stroke='%23808080' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  width: 25px;
  height: 25px;
}




html {
  background-color: #262626;
  font-family: 'LynkcoType-Regular';
}

html, body {
  background-color: #262626;
  font-family: 'LynkcoType-Regular';
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.snap-always {
  scroll-snap-stop: always;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOutAndScale {
  0%, 100% {
    opacity: 0;
    /* Combine scaling down with the translate operation for centering */
    transform: translate(-50%, -50%) scale(0.8);
  }
  10%, 90% {
    opacity: 1;
    /* Combine scaling to normal size with the translate operation for centering */
    transform: translate(-50%, -50%) scale(1);
  }
}

.animate-fadeInOutScale {
  animation: fadeInOutAndScale 1s ease-in-out;
  position: fixed;
  top: 50%;
  left: 50%;
  /* Initial transform for centering, might be redundant due to the animation but ensures the element is centered before animation starts */
  transform: translate(-50%, -50%);
  transform-origin: center; /* Ensure scaling centers correctly */
}


.fill-safearea {
  /* Add padding to account for the safe area */

  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
}

.fill-safearea-top {
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.animate-ping-loading {
  animation: ping-loading 1s cubic-bezier(0, 0, 0.8, 1) infinite;

}

@keyframes ping-loading {
  75%, 100% {
    transform: scale(1.1);
    opacity: 0;
  }
}

/* For browsers that support constant() but not env()  */
@supports (padding: max(0px)) {
  .fill-safearea {
      padding-top: max(env(safe-area-inset-top), constant(safe-area-inset-top));
      padding-left: max(env(safe-area-inset-left), constant(safe-area-inset-left));
      padding-right: max(env(safe-area-inset-right), constant(safe-area-inset-right));
      padding-bottom: max(env(safe-area-inset-bottom), constant(safe-area-inset-bottom));
  }
}



/* .bg-lynk-gradient {
	background: linear-gradient(-45deg, #23d5ab, #131313, #11211d, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

@keyframes gradient {
	0% {
		background-position: 100% 50%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
} */

.bg-lynk-gradient {
	/* background: linear-gradient(180deg, #0d5544, #131313); */
	background-size: 100% 100%;
  background: linear-gradient(180deg, #041c17, #131313), url(https://grainy-gradients.vercel.app/noise.svg);
	/* animation: gradient 4s ease infinite; */
	/* height: 200px; */
}

@keyframes gradient {
	0% {
		background-position: 0% 100%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 0% 100%;
	}
}